<template>
  <LoadingSpinner v-if="loadingAttendanceStudents || loadingEventAttendances" class="my-3" text="Loading Students..." />
  <table v-else class="app_table table table-striped table-sm">
    <thead class="table-light">
      <tr>
        <th
          v-tooltip="'Attended'"
          class="th_checkbox attendance_checkbox"
          scope="col"
        >
          ATT
        </th>
        <th
          v-tooltip="'No Show'"
          class="th_checkbox attendance_checkbox"
          scope="col"
        >
          NS
        </th>
        <th class="th_recouped" scope="col">
          Recouped
        </th>
        <th scope="col" class="th_name">
          Name
        </th>
        <th scope="col" class="th_surname">
          Surname
        </th>
        <th scope="col">
          E-mail
        </th>
        <th scope="col">
          Language
        </th>
        <th scope="col">
          Level
        </th>
        <th scope="col">
          Pause Until
        </th>
        <th class="th_checkbox flexi_checkbox" scope="col">
          Flexi Classes
        </th>
        <th class="th_checkbox remove_item" scope="col" />
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(attendance, index) in event.attendances"
        :key="attendance.id"
        :class="{'tr_on_pause': isStudentOnPause(attendance)}"
      >
        <td class="td_checkbox attendance_checkbox">
          <input
            v-model="attendance.attended"
            :value="attendance.attended"
            class="form-check-input d-block"
            type="checkbox"
            @change="attendedChanged(attendance)"
          >
        </td>
        <td class="td_checkbox attendance_checkbox">
          <input
            v-model="attendance.no_show"
            :value="attendance.no_show"
            class="form-check-input d-block"
            type="checkbox"
            @change="noShowChanged(attendance)"
          >
        </td>
        <td class="td_recouped_event">
          <div
            v-tooltip="attendance.recouped_event ? getRecoupedAttendanceTitle(attendance) : 'Edit Recouped'"
            class="input-group input-group-sm"
            @click="openRecoupedAttendanceSubModal(attendance)"
          >
            <input
              type="text"
              class="form-control"
              :value="formatDate(attendance.recouped_event?.start)"
              readonly
            >
            <button class="btn btn-outline-primary btn-sm" type="button">
              <i class="bi bi-pencil-fill" />
            </button>
          </div>
        </td>
        <td class="td_name">
          {{ getMapping("student", attendance.student).first_name }}
        </td>
        <td class="td_surname">
          {{ getMapping("student", attendance.student).last_name }}
        </td>
        <td>{{ getMapping("student", attendance.student).email }}</td>
        <td>{{ getMapping("class_language", getMapping("student", attendance.student).class_language, "-") }}</td>
        <td>{{ getMapping("level", getMapping("student", attendance.student).level, "-") }}</td>
        <td class="td_pause_until">
          <strong>{{ formatDate(getMapping("student", attendance.student)?.pause_until) }}</strong>
        </td>
        <td class="td_checkbox flexi_checkbox">
          <BooleanCheckCircle :is-true="getMapping('student', attendance.student).flexible_classes_count > 0" />
        </td>
        <td class="td_checkbox remove_item hover-pointer" @click="removeStudentEventAttendance(index)">
          <i class="bi bi-trash3-fill text-danger" />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { api } from "@/api";
import { defineComponent, ref, toRefs } from "vue";
import mappingsMixin from "@/mixins/mappings.mixin";
import IStudent from "@/types/student";
import IStudentEventAttendance from "@/types/student_event_attendance";
import { useModalStore } from "@/stores/modal.store";
import useDateMixin from "@/mixins/date.mixin";
import { useStudentStore } from "@/stores/student.store";
import LoadingSpinner from "@/components/ui/spinner/LoadingSpinner.vue";
import { IStudentEventAttendanceExtended } from "@/types/student_class_purchase";
import setsMixin from "@/mixins/sets.mixin";
import BooleanCheckCircle from "@/components/ui/BooleanCheckCircle.vue";
import CalendarClassEvent from "@/types/models/calendar_class_event";

export default defineComponent({
  name: "EventAttendanceTable",
  components: { BooleanCheckCircle, LoadingSpinner },
  mixins: [mappingsMixin, setsMixin],
  props: {
    modelValue: {
      type: CalendarClassEvent,
      default: () => new CalendarClassEvent(),
    },
  },
  emits: ["update:modelValue"],
  setup() {
    const modalStore = useModalStore();
    const studentStore = useStudentStore();
    const { attendanceStudents } = toRefs(studentStore);
    const { attendanceStudents: loadingAttendanceStudents } = toRefs(studentStore.loading);
    const loadingEventAttendances = ref(false);
    const searchQuery = ref("");

    const { formatDate, formatTime } = useDateMixin();
    return {
      modalStore,
      studentStore,
      formatDate,
      formatTime,
      attendanceStudents,
      loadingAttendanceStudents,
      loadingEventAttendances,
      searchQuery,
    };
  },
  computed: {
    attendanceStudentIds(): number[] {
      return this.event?.attendances.map((attendance) => attendance.student) ?? [];
    },
    event: {
      get() {
        return this.modelValue;
      },
      set(val: string) {
        this.$emit("update:modelValue", val);
      },
    },
  },
  watch: {
    "event.attendances": {
      async handler(
        newAttendances: IStudentEventAttendanceExtended[],
        oldAttendances: IStudentEventAttendanceExtended[],
      ) {
        // Fetch students, only if the students have changed.
        // Don't refetch only if some attendance property changed, like attendance.no_show.
        const oldStudents = new Set(oldAttendances.map((attendance) => attendance.student));
        const newStudents = new Set(newAttendances.map((attendance) => attendance.student));

        if (this.setsAreEqual(oldStudents, newStudents) || this.isSetSubset(newStudents, oldStudents)) {
          // Sets of old and new students are equal, it means it has not changed.
          // Or newStudents are a subset of the old students, which means some of them were removed.
          console.log("sets equal or subset of attendance students")
          console.log(newStudents);
          return;
        }

        await this.studentStore.getAttendanceStudents(this.attendanceStudentIds);
      },
    },
  },
  async created() {
    await this.studentStore.getAttendanceStudents(this.attendanceStudentIds);
    console.log("event", JSON.stringify(this.event.attendances, null, 2))
    // Fetch event to get all attendances with student data.
    if (this.event?.id) {
      this.loadingEventAttendances = true;
      const event = await api.calendarApi.getEvent(this.event?.id)
      this.event.attendances = event?.attendances ?? [];
    }
    this.loadingEventAttendances = false;
  },
  methods: {
    isStudentOnPause(attendance: any) {
      const pauseUntil = this.getMapping("student", attendance.student)?.pause_until;
      if (this.event?.start) {
        const eventStartDate = new Date(this.event?.start);
        const pauseUntilDate = new Date(pauseUntil);
        return pauseUntilDate >= eventStartDate;
      }
      return !!pauseUntil;
    },
    attendedChanged(attendance: any) {
      // Make attended and no show checkboxes behave like radio buttons.
      // Only one can be active.
      if (attendance.attended) {
        attendance.no_show = false;
      }
    },
    noShowChanged(attendance: any) {
      // Make attended and no show checkboxes behave like radio buttons.
      // Only one can be active.
      if (attendance.no_show) {
        attendance.attended = false;
      }
    },
    openRecoupedAttendanceSubModal(attendance: IStudentEventAttendance) {
      this.modalStore.openRecoupedAttendanceSubModal(attendance, this.selectedRecoupedAttendanceChanged);
    },
    getRecoupedAttendanceTitle(attendance: IStudentEventAttendance) {
      if (!attendance?.recouped_event) return "";
      const e = attendance.recouped_event;

      return `${this.formatDate(e.start, true)}
      ${e.title}
      ${this.formatTime(e.start)} - ${this.formatTime(e.end)}`;
    },
    selectedRecoupedAttendanceChanged(attendance: any, recoupedAttendance: number, recoupedEvent: any) {
      // Values must be null if they are not set, so that they are
      // included in the axios payload.
      attendance.recouped_attendance = recoupedAttendance ?? null;
      attendance.recouped_event = recoupedEvent ?? null;

      // Automatically select attended if he recouped.
      if (recoupedAttendance) {
        attendance.attended = true;
        attendance.no_show = false;
      }
    },
    removeStudentEventAttendance(index: number) {
      this.event?.attendances.splice(index, 1);
    },
    studentSort(a: IStudent, b: IStudent) {
      // Sort by title descending.
      console.log("student title", a.title, b.title)
      return a.title.localeCompare(b.title);
    },
  },
});
</script>

<style scoped lang="scss">
.th_recouped {
  text-align: right;
}
.td_surname,
.th_surname {
  width: 12rem;
  min-width: 12rem;
}
.td_name,
.th_name {
  width: 6rem;
  min-width: 6rem;
}
.th_recouped,
.td_recouped_event {
  width: 9rem;
  min-width: 9rem;
  input {
    padding-right: 0.325rem;
    padding-left: 0.325rem;
    text-align: center;
    text-overflow: ellipsis;

    &:hover {
      cursor: pointer;
    }
  }
}
.th_checkbox,
.td_checkbox {
  &.flexi_checkbox {
    width: 60px;
  }
  &.attendance_checkbox {
    width: 40px;

    .form-check-input {
      width: 1.2em;
      height: 1.2em;
      margin: auto;
    }
  }
  &.remove_item {
    width: 50px;
    font-size: 1.1rem;
  }
}
.tr_on_pause td {
  background: #d5bec5;
}
</style>
