import { defineStore } from "pinia";
import type { Component } from "vue";
import { shallowRef } from "vue";
import { api } from "@/api";
import StudentModal from "@/components/modals/student/StudentModal.vue";
import EventModal from "@/components/modals/calendar/event/EventModal.vue";
import ConfirmDeleteModal from "@/components/modals/calendar/ConfirmDeleteModal.vue";
import CalendarClassEvent from "@/types/models/calendar_class_event";
import SchoolClassModal from "@/components/modals/calendar/school_class/SchoolClassModal.vue";
import IStudent from "@/types/student";
import ISchoolClass from "@/types/school_class";
import SelectStudentsSubModal from "@/components/modals/student/SelectStudentsSubModal.vue";
import CalendarEvent from "@/types/models/calendar_event";
import Student from "@/types/models/student";
import DeleteStudentModal from "@/components/modals/student/DeleteStudentModal.vue";
import OurLanguageHourModal from "@/components/modals/calendar/event/OurLanguageHourModal.vue";
import SelectRecoupedAttendanceSubModal from "@/components/modals/calendar/event/SelectRecoupedAttendanceSubModal.vue";
import IStudentEventAttendance from "@/types/student_event_attendance";
import ITeacher from "@/types/teacher";
import TeacherModal from "@/components/modals/teacher/TeacherModal.vue";
import IStudentRegistration from "@/types/student_registration";
import StudentRegistrationModal from "@/components/modals/student/StudentRegistrationModal.vue";
import CompanyModal from "@/components/modals/company/CompanyModal.vue";
import ICompany from "@/types/company";
import SchoolClass from "@/types/models/school_class";
import useDateMixin from "@/mixins/date.mixin";

export interface IModalProps {
  component: null | Component;
  props?: object;
}

export interface IModalState {
  modal: IModalProps | null;
  subModal: IModalProps | null;
}

const baseState: IModalState = {
  modal: null,
  subModal: null,
};

// Access the functions from the mixin
const { formatDate, formatTime } = useDateMixin();

export const useModalStore = defineStore("modal", {
  state: (): IModalState => ({ ...baseState }),
  getters: {
    isScrollbarVisible() {
      return document.body.scrollHeight > window.innerHeight;
    },
  },
  actions: {
    openModal(payload: IModalProps) {
      this.modal = payload;
      this.hideScrollbar();
    },
    closeModal() {
      this.modal = null;
      this.showScrollbar();
    },
    openSubModal(payload: IModalProps) {
      this.subModal = payload;
      this.hideScrollbar();
    },
    closeSubModal() {
      this.subModal = null;
      this.showScrollbar();
    },
    closeModals() {
      this.closeModal();
      this.closeSubModal();
    },
    hideScrollbar() {
      const body = document.body;

      if (body) {
        body.style.overflow = "hidden";
        if (this.isScrollbarVisible) {
          body.style.paddingRight = "15px";
        }
      }
    },
    showScrollbar() {
      const body = document.body;

      if (body && !this.modal && !this.subModal) {
        body.style.overflow = "auto";
        body.style.paddingRight = "0";
      }
    },
    openEventModal(info: any) {
      this.openModal({
        component: shallowRef(EventModal),
        props: { dateEventInfo: info },
      });
    },
    openOurLanguageHourModal(info: any) {
      this.openModal({
        component: shallowRef(OurLanguageHourModal),
        props: { dateEventInfo: info },
      });
    },
    openDeleteEventModal(classEvent: CalendarEvent | CalendarClassEvent, confirmDeleteCallback?: () => void) {
      this.openSubModal({
        component: shallowRef(ConfirmDeleteModal),
        props: {
          objectName: "Event",
          title: classEvent.title,
          confirmDeleteCallback: confirmDeleteCallback,
        },
      });
    },
    async openDeleteSchoolClassModal(schoolClass: SchoolClass, confirmDeleteCallback?: () => void) {
      let response;
      try {
        response = await api.calendarApi.getSchoolClassEvents(schoolClass);
      } catch (error: any) {
        console.error(error);
      }
      const relatedEvents = response?.data.map((event: any) => {
        return formatDate(event.start)  + " (" + formatTime(event.start) + " - " + formatTime(event.end) + ")"
      }) ?? [];
      console.log(response?.data);
      console.log(relatedEvents);
      this.openSubModal({
        component: shallowRef(ConfirmDeleteModal),
        props: {
          objectName: "Class",
          title: schoolClass.title,
          relatedObjects: relatedEvents,
          relatedObjectsName: "class events",
          confirmDeleteCallback: confirmDeleteCallback,
        },
      });
    },
    openDeleteStudentModal(student: Student, confirmDeleteCallback?: () => void) {
      this.openSubModal({
        component: shallowRef(DeleteStudentModal),
        props: {
          student: student,
          confirmDeleteCallback: confirmDeleteCallback,
        },
      });
    },
    openSelectStudentsSubModal(
      studentIds: number[],
      selectedStudentsCallback?: (_: number[]) => void,
      selectSingleStudent: boolean = false,
    ) {
      this.openSubModal({
        component: shallowRef(SelectStudentsSubModal),
        props: {
          selectedStudentIds: studentIds,
          selectedStudentsCallback: selectedStudentsCallback,
          selectSingleStudent: selectSingleStudent,
        },
      });
    },
    openRecoupedAttendanceSubModal(
      attendance: IStudentEventAttendance,
      selectedCallback?: (origAtt: IStudentEventAttendance, recoupedAtt: number, recoupedEvent: any) => void,
    ) {
      this.openSubModal({
        component: shallowRef(SelectRecoupedAttendanceSubModal),
        props: {
          attendance: attendance,
          selectedCallback: selectedCallback,
        },
      });
    },
    openStudentModal(student?: IStudent) {
      this.openModal({
        component: shallowRef(StudentModal),
        props: {
          studentProp: student,
        },
      });
    },
    openStudentRegistrationModal(studentRegistration?: IStudentRegistration) {
      this.openModal({
        component: shallowRef(StudentRegistrationModal),
        props: {
          studentRegistration: studentRegistration,
        },
      });
    },
    openTeacherModal(teacher?: ITeacher) {
      this.openModal({
        component: shallowRef(TeacherModal),
        props: {
          teacherProp: teacher,
        },
      });
    },
    openSchoolClassModal(schoolClass?: ISchoolClass) {
      this.openModal({
        component: shallowRef(SchoolClassModal),
        props: {
          schoolClassProp: schoolClass,
        },
      });
    },
    openCompanyModal(company?: ICompany) {
      this.openModal({
        component: shallowRef(CompanyModal),
        props: {
          companyProp: company,
        },
      });
    },
  },
});
